import { attachEvent } from '../../../utils/base.utils';

export function loadDropddownEvents() {
	attachEvent('click', '.ad-universalnav .ad-component_dropdown .ad-component_dropdown--trigger', (evt, activeDropdownLink) => {
		evt.stopPropagation();
		var activeDropdown = activeDropdownLink.closest('.ad-component_dropdown');
		[].forEach.call(document.querySelectorAll('.ad-universalnav .ad-component_dropdown'), (dropdownLink) => {
			if (!activeDropdown.isEqualNode(dropdownLink)) {
				dropdownLink.classList.remove('js-dropdown-active');
				var $trigger = dropdownLink.querySelector('.ad-component_dropdown--trigger');
				if ($trigger) {
					$trigger.classList.remove('ad-component_dropdown--is-active');
				}
			}
		});
		document.dispatchEvent(new CustomEvent('universalnav:hide:cart'));
		activeDropdown.setAttribute('data-tooltip-hide', 'true');
		activeDropdown.removeAttribute('data-adu-tooltip');
		activeDropdownLink.classList.toggle('ad-component_dropdown--is-active');
		activeDropdown.classList.toggle('js-dropdown-active');
	});
	// Hide dropdown when clicking anywhere on the page
	attachEvent('click', 'body', () => {
		[].forEach.call(document.querySelectorAll('.ad-universalnav .ad-component_dropdown'), (el) => {
			el.classList.remove('js-dropdown-active');
			var trigger = el.querySelector('.ad-component_dropdown--trigger');
			if (trigger) {
				trigger.classList.remove('ad-component_dropdown--is-active');
			}
		});
		document.dispatchEvent(new CustomEvent('universalnav:hide:cart'));
	}, false);

	// Hide dropdown when tabbing on the page
	attachEvent('keyup', 'body', (e) => {
		if (e.key === "Tab") {
			e.preventDefault();
			const activeDropdown = document.querySelector('.js-dropdown-active');
			if (!activeDropdown?.contains(document.activeElement)) {
				const menuList = document.querySelectorAll('.ad-universalnav .ad-component_dropdown');
				menuList.forEach((el) => {
					el.classList.remove('js-dropdown-active');
					const trigger = el.querySelector('.ad-component_dropdown--trigger');
					if (trigger) {
						trigger.classList.remove('ad-component_dropdown--is-active');
					}
				});
				document.dispatchEvent(new CustomEvent('universalnav:hide:cart'));
			}
		}
	}, false);
}

const isEllipsisActive = e => e.offsetWidth < e.scrollWidth;
const ELLIPSIS_SELECTOR = '.ad-universalnav .ellipsize-overflow-text';

export function loadMouseOverEvents() {
	attachEvent('mouseover', '.ad-universalnav .js-mouseover--trigger', (evt, activeElement) => {
		if (activeElement.classList.contains('js-dropdown-active') && !activeElement.classList.contains('js-mouseover--trigger')) {
			return;
		}
		activeElement.classList.add('js-dropdown-active');
	});
	attachEvent('mouseout', '.ad-universalnav .js-mouseover--trigger', (evt, activeElement) => {
		activeElement.classList.remove('js-dropdown-active');
	});
	if (document.querySelector(ELLIPSIS_SELECTOR) && !isEllipsisActive(document.querySelector(ELLIPSIS_SELECTOR))) {
		attachEvent('mouseover', ELLIPSIS_SELECTOR, (evt, activeElement) => {
			if (activeElement.closest('li').getAttribute('data-tooltip-hide') !== 'true') {
				activeElement.closest('li').setAttribute('data-adu-tooltip', activeElement.textContent);
			}
		});
		attachEvent('mouseout', ELLIPSIS_SELECTOR, (evt, activeElement) => {
			activeElement.closest('li').removeAttribute('data-adu-tooltip');
		});
	}
}
