import { getConfigs } from '../confs/store.conf';
import { userHasOneOfRoles } from '../utils/conf.utils';
import loadScript from './load-script';

const merchantRoles = [
	'CHANNEL_PRODUCT_SUPPORT',
	'CHANNEL_SUPPORT',
	'CHANNEL_ADMIN',
	'REPORTS_CHANNEL_ADMIN',
	'CORPORATE_ADMIN',
	'SALES_SUPPORT',
	'RESELLER_MANAGER',
	'SUPERUSER',
	'SUPER_SUPPORT',
];

const staticMerchantNav = `${location.origin}/merchant-nav/merchant-nav.js`;

const isIframe = () => {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
};

const hideTrialBanner = () => {
	const trialBanner = document.querySelector('#header #fixedNav');
	if (trialBanner) {
		trialBanner.style.display = 'none';
	} else {
		const styleSheet = document.createElement('style');
		styleSheet.type = 'text/css';
		styleSheet.innerText = '#header #fixedNav {display: none;}';
		document.head.appendChild(styleSheet);
	}
};
export const needsMerchantNav = () => {
	const channel = getConfigs('CHANNEL_SETTINGS');
	const isMerchantMarketplace = channel.selfserveMarketplace;
	const isMerchantNavPage = !getConfigs('isMarketplace') && getConfigs('useTemplate') !== 'headerCheckout';
	const isExpired = channel ? channel.accountStatus === 'FREE_TRIAL_EXPIRED' : false;
	const forceMerchantNav = window.sessionStorage.getItem('forceGlobalHeader') === 'true';

	if(forceMerchantNav) {
		return true;
	}

	return (
		isMerchantMarketplace &&
		!isIframe() &&
		isMerchantNavPage &&
		(userHasOneOfRoles(merchantRoles) || isExpired)
	);
};

const loadInterFont = () => {
	const link = document.createElement('link');
	link.rel = 'stylesheet';
	link.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@100..700&display=block';
	document.head.appendChild(link);
};

// help pages render better with a min-height that stop the page jumping around
const setHeaderheight = (headerSelector, partner) => {
	// this is set by merchant nav, we only do this for v2
	if(window.sessionStorage.getItem(`AD:APPS:STORAGE:feature-flag-merchant-nav-v2:${partner}`) === 'true') {
		const header = document.querySelector(headerSelector);
		if (header) {
			header.style.minHeight = '64px';
			header.style.backgroundColor = '#000000';
		}
	}
}

let loaded = false;
export const loadMerchantNav = () => {
	if (!loaded) {
		const {
			channel,
			footer,
			isPositionFixed,
			marketUrl,
			token,
			bootstrap,
			myapps,
			header,
			CHANNEL_SETTINGS,
		} = getConfigs();

		const merchantNavConfig = {
			channel,
			isPositionFixed,
			marketUrl,
			token,
			bootstrap,
			myapps,
			header,
			CHANNEL_SETTINGS,
		};
		window.ad_merchant_nav_config = merchantNavConfig;
		window.ad_merchant_nav_config.footer = footer;
		setHeaderheight(merchantNavConfig.header.renderTo, CHANNEL_SETTINGS?.partner);
		hideTrialBanner();
		loadInterFont();
		loadScript(staticMerchantNav);
		loaded = true;
	} else {
		document.dispatchEvent(new CustomEvent('mnav:reload'));
	}
};
